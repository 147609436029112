import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import freePricingIcon from "../../assets/images/price-free-icon.png";
import proPricingIcon from "../../assets/images/price-pro-icon.png";
import ProductPlanCard from "./components/ProductPlanCard";
import { connect, useDispatch } from "react-redux";
import {
    deletePricingPlanAdmin,
    getAllPricingPlansListAdmin,
} from "../../../redux/actions/admin/pricingPlans";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import { Link } from "react-router-dom";
import EditPricingPlan from "./components/EditPricingPlan";
import { checkServerURL } from "../../../helper/helper";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import trashUserIcon from "../../assets/images/tash-user-icon.png";
import {
    errorMessageModal,
    successMessageModal,
} from "../../components/Modal/MessagesModal";
import Loader from "../../../components/Loader/Loader";
import NotFound from "../../../components/NotFound/NotFound";

const ManagePricingPlans = ({ pricingPlanList, isLoading, notFound }) => {
    const adminID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const dispatch = useDispatch();
    const [activePlanCard, setActivePlanCard] = useState(0);
    const [activePlanDetails, setActivePlanDetails] = useState(null);
    const [editPricinPlanCard, setShowEditPricinPlanCard] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [getItem, setItems] = useState(null);

    const pricingplansData = pricingPlanList?.data && pricingPlanList?.data;

    useEffect(() => {
        if (adminID) {
            dispatch(getAllPricingPlansListAdmin(adminID));
        }
    }, [adminID]);

    useEffect(() => {
        let firstPlan = pricingplansData && pricingplansData?.[0];
        setActivePlanDetails(firstPlan);
    }, [pricingplansData]);

    const handlePricingDuration = (price, duration) => {
        if(duration >= 12){
            return `$${price} per Year USD`;
        }else if(duration < 12 && duration > 0){
            return `$${price} per Month USD`;
        }else {
            return `$${0} Enjoy limited features without any charge`;
        }
    };

    const handlePricingPlanStatus = (status) => {
        switch (status) {
            case "0":
                return { status: "Inactive", class: "inactive" };
            case "1":
                return { status: "Active", class: "active" };
            default:
                return { status: "Inactive", class: "inactive" };
        }
    };

    const handleDeletePlan = (res) => {
        if (res === true) {
            setLoading(true);
            setShowConfirmModal(false);
            const payload = {
                id: getItem?.id,
                admin_id: Number(adminID),
            };

            dispatch(
                deletePricingPlanAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Pricing Plan Deleted successful.",
                            "Successful delete a pricing plan."
                        );
                        dispatch(getAllPricingPlansListAdmin(adminID));
                        setLoading(false);
                        setActivePlanCard(0);
                    } else if (type === "fail") {
                        setLoading(false);
                        errorMessageModal("Delete Pricing Plan", res?.message);
                    }
                })
            );
        }
    };

    return (
        <div>
            <AdminLayout pageTitle={"pricing-plans"}>
                <div className="pricing-plans-content">
                    <div className="table-management-section">
                        <div className="management-header mb-5">
                            <h4>Pricing Plan Settings</h4>
                            <p>
                                You can manage pricing plan, can be create new plan, edit and
                                view the plan.
                            </p>
                        </div>
                    </div>
                    <div className="pricing-plans-content-inner">
                        <div className="row">
                            <div className="col-md-8 pricing-plans-column">
                                <div className="pricing-plans-card card">
                                    <div className="card-header d-flex justify-content-between">
                                        <h5 className="">Products</h5>
                                        <Link
                                            to={"/admin/pricing-plan/create"}
                                            className="btn theme-blue-btn"
                                        >
                                            Create new plan
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        {isLoading ? <div className="plan-loader-outer"><Loader /></div> : notFound ? <NotFound /> : (
                                            pricingplansData &&
                                            pricingplansData?.map((item, ind) => {
                                                return (
                                                    <ProductPlanCard
                                                        key={ind}
                                                        planIcon={
                                                            !item?.picture ||
                                                                item?.picture === "null" ||
                                                                item?.picture === null
                                                                ? freePricingIcon
                                                                : checkServerURL(API_URL, item?.picture)
                                                        }
                                                        planLabel={item?.price_string}
                                                        planTitle={handlePricingDuration(
                                                            Number(item?.price),
                                                            Number(item?.duration)
                                                        )}
                                                        extraClass={ind === activePlanCard ? "active" : ""}
                                                        onClick={() => {
                                                            setActivePlanCard(ind);
                                                            setActivePlanDetails(item);
                                                        }}
                                                        editBtnClick={() => {
                                                            setShowEditPricinPlanCard(!editPricinPlanCard);
                                                            setActivePlanDetails(item);
                                                        }}
                                                        deleteBtnClick={() => {
                                                            setShowConfirmModal(!showConfirmModal);
                                                            setItems(item);
                                                        }}
                                                        isLoading={loading}
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 pricing-plans-column pricing-plans-details-sec">
                                {activePlanDetails && !isLoading && (
                                    <div className="pricing-plans-card card">
                                        <div className="card-header">
                                            <h5 className="">Details</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-card-details">
                                                <div className="details-column d-flex align-items-center gap-2">
                                                    <span>Status:</span>
                                                    <p
                                                        className={`btn m-0 status ${handlePricingPlanStatus(activePlanDetails?.status)
                                                            .class
                                                            }`}
                                                    >
                                                        {
                                                            handlePricingPlanStatus(activePlanDetails?.status)
                                                                .status
                                                        }
                                                    </p>
                                                </div>
                                                <div className="details-column d-flex align-items-center gap-2">
                                                    <span>Date Created:</span>
                                                    <p className="m-0">{activePlanDetails?.created_at}</p>
                                                </div>
                                                <div className="details-column d-flex align-items-center gap-2">
                                                    <span>Payment:</span>
                                                    <p className="m-0">
                                                        $ {activePlanDetails?.price} USD
                                                    </p>
                                                </div>
                                                <div className="details-column d-flex align-items-center gap-2">
                                                    <span>Plan Details:</span>
                                                    <p className="m-0">
                                                        {handlePricingDuration(
                                                            Number(activePlanDetails?.price),
                                                            Number(activePlanDetails?.duration)
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {editPricinPlanCard && (
                                <EditPricingPlan
                                    closeModal={setShowEditPricinPlanCard}
                                    pricingData={activePlanDetails}
                                />
                            )}
                            {showConfirmModal && (
                                <ConfirmationModal
                                    isShowModal={showConfirmModal}
                                    closeModal={setShowConfirmModal}
                                    callBack={handleDeletePlan}
                                    isLoading={false}
                                    isDisabled={false}
                                    icon={trashUserIcon}
                                    btnText={"Continue"}
                                    messageTitle={<>Are You Sure to Delete the Pricing Plan?</>}
                                    description={
                                        <>
                                            Proceed with before taking any steps
                                            <br /> to delete a Pricing Plan.
                                        </>
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </div>
    );
};

const mapStateToProps = (state) => ({
    pricingPlanList: state?.admin_pricingplan?.list,
    isLoading: state?.admin_pricingplan?.is_loading,
    notFound: state?.admin_pricingplan?.notFound
});

export default connect(mapStateToProps, { getAllPricingPlansListAdmin })(
    ManagePricingPlans
);
