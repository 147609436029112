import {
  GET_STARRED_VIDEOS,
  GET_VIDEOS,
  UPDATE_STARRED_VIDEO,
  NOT_FOUND_STARRED_VIDEO,
  EDITED_VIDEO_DETAILS,
  SHORT_VIDEOS,
  SHORT_STARRED_VIDEOS,
  RENAME_CARD,
  REMOVE_CARD,
  UPLOAD_VIDEO,
  SEND_BY_EMAIL,
  EDIT_VIDEO,
  NOT_FOUND_VIDEO,
  LOADING_VIDEOS,
  LOADING_STARRED_VIDEOS,
  SEARCH_VIDEOS,
  SEARCH_STARRED_VIDEOS,
} from "./type";
import { API_URL } from "../../utils/config";
import axios from "axios";

// withCredentials
axios.withCredentials = false;

export const manageVideos = (userID, authToken, search) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_VIDEOS,
      payload: null,
    });
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/videos/list`, data, config);
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_VIDEO,
            payload: null,
          });
        } else {
          dispatch({
            type: GET_VIDEOS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const manageStarredVideos = (userID, authToken) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_STARRED_VIDEOS,
    });
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/videos/starred-list`,
        data,
        config
      );
      if (res?.data?.data?.length <= 0) {
        dispatch({
          type: NOT_FOUND_STARRED_VIDEO,
          payload: null,
        });
      } else {
        dispatch({
          type: GET_STARRED_VIDEOS,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const updateStarredVideo = (payload, callBack) => {
  return (dispatch) => {
    try {
      payload?.id &&
        payload?.id?.map(async (id) => {
          let data = JSON.stringify({
            is_starred: payload?.status,
            id: id,
            access_token: payload?.access_token,
            user_id: payload?.user_id,
          });

          let config = {
            maxBodyLength: Infinity,
            headers: {
              "Content-Type": "application/json",
            },
          };
          try {
            const res = await axios.post(
              `${API_URL}/videos/starred`,
              data,
              config
            );
            if (res?.data?.status === true) {
              dispatch({
                type: UPDATE_STARRED_VIDEO,
                payload: res?.data,
              });
              callBack("success", res);
            }
          } catch (error) {
            console.log(error, "error==>");
            let message = error?.response?.data;
            callBack("fail", message);
          }
        });
    } catch (err) {
      callBack("fail", "Something went wrong!");
    }
  };
};

export const renameVideos = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        name: payload?.name,
        title: payload?.title,
        summary: payload?.summary,
        tags: payload?.tags,
        id: payload?.id,
        access_token: payload?.access_token,
        user_id: payload?.user_id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/videos/rename`, data, config);
      if (res?.data?.status === true) {
        dispatch({
          type: RENAME_CARD,
          payload: res?.data,
        });
        callBack("success", res);
      }
    } catch (err) {
      let message = err?.response?.data;
      callBack("fail", message);
    }
  };
};

export const shareByEmailVideo = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        vid: payload?.id,
        toname: payload?.toname,
        emailId: payload?.emailId,
        userMessage: payload?.userMessage,
        access_token: payload?.access_token,
        user_id: payload?.userId,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/videos/send-email`,
        data,
        config
      );
      if (res?.data?.status === true) {
        dispatch({
          type: SEND_BY_EMAIL,
          payload: res?.data,
        });
        callBack("success");
      }
    } catch (err) {
      callBack("failed");
      console.log(err, "---Error---");
    }
  };
};

export const removeVideo = (payload, callBack) => {
  return (dispatch) => {
    try {
      if (window.confirm("Are you sure?")) {
        payload?.id &&
          payload?.id?.map(async (id) => {
            let data = JSON.stringify({
              id: id,
              access_token: payload?.access_token,
              user_id: payload?.user_id,
            });
            let config = {
              maxBodyLength: Infinity,
              headers: {
                "Content-Type": "application/json",
              },
            };
            try {
              const res = await axios.post(
                `${API_URL}/videos/delete`,
                data,
                config
              );
              if (res?.data?.status === true) {
                dispatch({
                  type: REMOVE_CARD,
                  payload: res?.data,
                });
                callBack("success");
              }
            } catch (error) {
              let message = error?.response?.data;
              callBack("fail", message);
            }
          });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

// multi delete
export const multiRemoveVideo = (payload, callBack) => {
  return async (dispatch) => {
    try {
      if (window.confirm("Are you sure?")) {
        let data = JSON.stringify({
          ids: payload?.id,
          access_token: payload?.access_token,
          user_id: payload?.user_id,
        });
        let config = {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        try {
          const res = await axios.delete(`${API_URL}/videos/delete`, config);
          if (res?.data?.status === true) {
            dispatch({
              type: REMOVE_CARD,
              payload: res?.data,
            });
            callBack("success", res);
          }
        } catch (err) {
          let message = err?.response?.data;
          callBack("fail", message);
        }
      }
    } catch (err) {
      callBack("fail", "Something went wrong!");
    }
  };
};

export const shortingVideos = (userID, authToken, type) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/videos/list?sort=${type}`,
        data,
        config
      );
      if (res) {
        dispatch({
          type: SHORT_VIDEOS,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const shortingStarredVideos = (userID, authToken, type) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/videos/starred-list?sort=${type}`,
        data,
        config
      );
      if (res) {
        dispatch({
          type: SHORT_STARRED_VIDEOS,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const manageVideoDetails = (payload, callBack) => {
  return async (dispatch) => {
    try {

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/videos/details?vid=${payload?.id}`,
        config
      );

      if (res) {
        dispatch({
          type: EDITED_VIDEO_DETAILS,
          payload: res?.data?.data,
        });
        callBack("success", res?.data?.data);
      }
    } catch (error) {
      callBack("failed", error);
    }
  };
};

export const uploadRecordingVideo = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = new FormData();
      data.append("name", payload?.name);
      data.append("user_id", payload?.user_id);
      data.append("file_size", payload?.file_size);
      data.append("recording", payload?.file);
      data.append("file_duration", payload?.file_duration);
      data.append("access_token", payload?.access_token);
      data.append("title", payload?.title);
      data.append("summary", payload?.summary);
      data.append("tags", payload?.tags);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const res = await axios.post(`${API_URL}/videos/upload`, data, config);
      if (res) {
        dispatch({
          type: UPLOAD_VIDEO,
          payload: res?.data,
        });
        callBack("success", res);
      }
    } catch (error) {
      let message = error?.response?.data;
      if (error?.response) {
        callBack("fail", message);
      } else {
        let message = { message: 'Something went wrong!' }
        callBack("fail", message);
      }
    }
  };
};

export const downloadVideo = async (id,extType, callBack) => {
  try {
    let data = JSON.stringify({
      type: extType,
      vid: id,
    });

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(`${API_URL}/videos/download`, data, config);
    if(response?.data?.status){
      callBack(response?.data, "success");
    }
  } catch (error) {
    let message = error?.response?.data;
    if (error?.response) {
      callBack(message, "fail");
    } else {
      let message = { message: 'Something went wrong!' }
      callBack(message, "fail");
    }
  }
};

export const manageEditVideo = (payload, callBack) => {
  return async (dispatch) => {
    try {
      const FormData = require("form-data");
      payload?.video_tacked.push({
        id:2,
        click : 'Request went to API',
        time : new Date().toLocaleString("en-GB", { timeZone: "Europe/Paris", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }) + ':' + new Date().getMilliseconds().toString().padStart(3, '0')
      });
      let data = new FormData();
      data.append("video_id", payload?.id);
      data.append("user_id", payload?.user_id);
      data.append("access_token", payload?.access_token);
      data.append("text_points", JSON.stringify(payload?.text_points));
      data.append("image_points", JSON.stringify(payload?.image_points));
      data.append("video_points", JSON.stringify(payload?.video_points));
      data.append("blur_points", JSON.stringify(payload?.blur_points));
      data.append("crop_points", JSON.stringify(payload?.crop_points));
      data.append("bgMusic_points", JSON.stringify(payload?.bgMusic_points));
      data.append("video_speed", JSON.stringify(payload?.video_speed));
      data.append("video_dimension", JSON.stringify(payload?.video_dimension));
      data.append("transcript", JSON.stringify(payload?.transcript));
      data.append("video_effects", JSON.stringify(payload?.video_effects));
      data.append("insert_points", JSON.stringify(payload?.insert_points));
      data.append("waterMark", JSON.stringify(payload?.waterMark));
      data.append("gif_points", JSON.stringify(payload?.gif_points));
      data.append("video_tacked", JSON.stringify(payload?.video_tacked));
      data.append(
        "remove_filler_words",
        JSON.stringify(payload?.remove_filler_words)
      );
      data.append("remove_silence", JSON.stringify(payload?.remove_silence));

      payload?.bgMusic &&
        payload?.bgMusic?.map((file) => {
          data.append("bg_files", file);
        });
      payload?.bgImage &&
        payload?.bgImage?.map((file) => {
          data.append("bg_files", file);
        });
      payload?.bgVideo &&
        payload?.bgVideo?.map((file) => {
          data.append("bg_files", file);
        });
      payload?.insertData &&
        payload?.insertData?.map((file) => {
          data.append("bg_files", file);
        });
      if (payload?.watermarkImage) {
        data.append("bg_files", payload?.watermarkImage);
      }
      const res = await axios.post(`${API_URL}/videos/edit`, data);
      if (res) {
        dispatch({
          type: EDIT_VIDEO,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error?.response?.data;
      if (error?.response) {
        callBack("fail", message);
      } else {
        let message = { message: 'Something went wrong!' }
        callBack("fail", message);
      }
    }
  };
};

export const uploadVideoOnGoogleDrive = (payload, callBack) => {
  return async (dispatch) => {
    let url = "";
    if (payload?.uploadType === "google") {
      url = API_URL + "/integration/upload-drive";
    } else if (payload?.uploadType === "youtube") {
      url = API_URL + "/integration/upload-youtube";
    }
    try {

      let data = null;
      if (payload?.mediaType === "video") {
        data = JSON.stringify({
          "token": payload?.token,
          "vId": payload?.id
        });
      }else if(payload?.mediaType === "image"){
        data = JSON.stringify({
          "token": payload?.token,
          "sId": payload?.id
        });
      }

      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      };

      const res = await axios.post(`${url}`, data, config);

      if (res) {
        callBack("success", res?.data);
      }
    } catch (err) {
      callBack("failed", err);
      console.log(err, "---Error---");
    }
  };
};

export const manageSearchVideos = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_VIDEOS,
      payload: null,
    });
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/videos/list?search=${payload?.search_text}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_VIDEO,
            payload: null,
          });
        } else {
          dispatch({
            type: SEARCH_VIDEOS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const editVideoTitleAndSummary = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        title: payload?.title,
        summary: payload?.summary,
        id: payload?.id,
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/videos/add-summary`,
        data,
        config
      );
      if (res?.data?.status) {
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error.response.data;
      callBack("failed", message);
    }
  };
};

export const manageSearchStarredVideos = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_STARRED_VIDEOS,
    });
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/videos/starred-list?search=${payload?.search_text}`,
        data,
        config
      );

      if (res?.data?.data?.length <= 0) {
        dispatch({
          type: NOT_FOUND_STARRED_VIDEO,
          payload: null,
        });
      } else {
        dispatch({
          type: SEARCH_STARRED_VIDEOS,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};
