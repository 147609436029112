import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../views/login/Login";
import Home from "../views/home/Home";
import Dashboard from "../views/dashboard/Dashboard";
import EditVideo from "../views/edit-video/EditVideo";
import Starred from "../views/starred/Starred";
import AboutUs from "../views/about-us/AboutUs";
import ThankYou from "../views/thank-you/ThankYou";
import Uninstall from "../views/uninstall/Uninstall";
import UploadVideo from "../views/upload-video/UploadVideo";
import AdminLogin from "../admin/views/login/AdminLogin";
import PrivacyPolicy from "../views/privacy-policy/privacy-policy";
import SignUp from "../views/login/components/SignUp";
import AuthenticatedRoutes from "./authenticatedRoutes";
import Callback from "../views/login/components/SocialLogin/Callback";
import { ShareMedia } from "../views/share-media/ShareMedia";
import MaintenanceModeRoute from "./MaintenanceModeRoute";
import Screenshot from "../views/screenshot/Screenshot";
import Video from "../views/video/Video";
import ForgotPassword from "../views/login/components/ForgotPassword";
import VerifyEmail from "../views/login/components/VerifyEmail";
import CreateNewPassword from "../views/login/components/CreateNewPassword";
import AdminDashboard from "../admin/views/dashboard/Dashboard";
import UsersList from "../admin/views/users/UsersList";
import MembershipList from "../admin/views/membership/MembershipList";
import AddPricingPlan from "../admin/views/pricing-plan/AddPricingPlan";
import ManagePricingPlans from "../admin/views/pricing-plan/ManagePricingPlans";
import ManageExtensionSettings from "../admin/views/extension-settings/ManageExtensionSettings";
import ManageGeneralSettings from "../admin/views/general-settings/ManageGeneralSettings";
import TagsList from "../admin/views/tags/TagsList";
import ManageUserProfile from "../admin/views/users/ManageUserProfile";
import ManageImageAndVideo from "../admin/views/media/ManageImageAndVideo";
import AdminRoutes from "./AdminRoutes";
import SavedSnippets from "../views/saved-snippets/SavedSnippets";

const CustomRoutes = () => {
  const [userRole, setUserRole] = useState(0);
  return (
    <>
      <Routes>
        <Route element={<MaintenanceModeRoute />}>
          <Route element={<AuthenticatedRoutes getUserRole={setUserRole} />}>
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/starred" element={<Starred />} exact />
            <Route path="/ocr-snippets" element={<SavedSnippets />} exact />
            <Route path="/edit-video/:id" element={<EditVideo />} exact />
            <Route path="/video-upload" element={<UploadVideo />} exact />
            <Route element={<AdminRoutes userRole={userRole} />}>
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/manage-users" element={<UsersList />} />
              <Route path="/admin/manage-user-profile/:id" element={<ManageUserProfile />} />
              <Route path="/admin/manage-image&video" element={<ManageImageAndVideo />} />
              <Route path="/admin/manage-membership" element={<MembershipList />} />
              <Route path="/admin/manage-pricing-plans" element={<ManagePricingPlans />} />
              <Route path="/admin/pricing-plan/create" element={<AddPricingPlan />} />
              <Route path="/admin/manage-tags" element={<TagsList />} />
              <Route path="/admin/extension-static-ui&text" element={<ManageExtensionSettings />} />
              <Route path="/admin/manage-general-settings" element={<ManageGeneralSettings />} />
            </Route>
          </Route>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="/create-password/:token" element={<CreateNewPassword />} />
          <Route path="/screenshot/:id" element={<Screenshot />} exact />
          <Route path="/video/:id" element={<Video />} exact />
        </Route>
        <Route path="/share/:id/:type" element={<ShareMedia />} exact />
        <Route path="/dashboard/callback" element={<Callback />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/about-us" element={<AboutUs />} exact />
        <Route path="/thank-you" element={<ThankYou />} exact />
        <Route path="/uninstall" element={<Uninstall />} exact />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
};

export default CustomRoutes