import React, { useState } from "react";
import logoImg from "../../../assets/images/home/ocr-logo.png";
import loadingIcon from "../../../assets/images/light-loader.gif";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { failureMessagePopup } from "../../../helper/helper";
import { createNewPassword } from "../../../redux/actions/authentication";
import { createNewpasswordSchema } from "./Validation";
import SuccessVerifyModal from "../../../components/Modals/SuccessVerifyModal";
import showPassIcon from "../../../assets/images/eye-pass-icon.png";
import hidePassIcon from "../../../assets/images/hidden-pass-icon.png";
import AdSenseAd from "../../../components/Adsense/adSenseAd";
import AuthChecker from "../../../helper/AuthChecker";

const CreateNewPassword = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [message, setMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const searchToken = location?.search?.replace("?=", "");
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState("");
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });

    const handleSubmit = () => {
        const paylaod = {
            password: formData?.password,
            token: searchToken,
        };
        if (createNewpasswordSchema({ formData, setFormErrors })) {
            setLoading(true);
            dispatch(
                createNewPassword(paylaod, (type, res) => {
                    if (type === "success") {
                        setMessage(res?.message);
                        setLoading(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", res?.message);
                        setLoading(false);
                    }
                })
            );
        }
    };

    const handleGoLoginPage = (e) => {
        navigate("/login");
    };

    return (
        <div className="login-page">
            <AuthChecker />
            <div id="quix-dashboard-wrapper">
                {message && (
                    <SuccessVerifyModal
                        message={message}
                        btnCallBack={(e) => handleGoLoginPage(e)}
                        btnTitle={"Login Here"}
                    />
                )}
                <div id="quix-dashboard-wrapper">
                    <div id="quix-dashboard-inner">
                        <div className="login-logo-outer">
                            <div className="login-logo-inner">
                                <Link to={"/"}>
                                    <img src={logoImg} />
                                </Link>
                            </div>
                        </div>
                        <div className="login-outer-box">
                            <div className="adsense-box">
                                <AdSenseAd
                                    client="ca-pub-4424794709901498"
                                    slot="3435754143"
                                    format="vertical"
                                    style={{ display: 'block', minWidth: "300px", maxWidth: "400px", width: "100%", height: "100%" }}
                                    responsive={true}
                                />
                            </div>
                            {/* form outer */}
                            <div className="login-form-outer">
                                <div className="login-form-inner">
                                    <span className="form-title">Create new Password</span>
                                    <span className="form-subtitle">
                                        To get started, please sign in
                                    </span>
                                </div>
                                <div className="login-with-details">
                                    <div className="custom-login-details">
                                        <div className="custom-details-form card">
                                            <div className="card-body">
                                                <div className="mb-3 user-password-filed">
                                                    <label htmlFor="password" className="form-label">
                                                        Password
                                                    </label>
                                                    <div className="password-eyes quix-edit-password">
                                                        <input
                                                            type={!showPassword ? "password" : "text"}
                                                            className="form-control"
                                                            id="Password"
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    password: e.target.value,
                                                                })
                                                            }
                                                            placeholder="Enter your password"
                                                            defaultValue={formData?.password}
                                                            maxLength={255}
                                                        />
                                                        <img
                                                            src={!showPassword ? hidePassIcon : showPassIcon}
                                                            height={"100%"}
                                                            width={"100%"}
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        />
                                                    </div>
                                                    {formErrors?.passwordError && (
                                                        <p className="text-danger account-dec m-0">
                                                            {formErrors?.passwordError}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3 user-password-filed">
                                                    <label htmlFor="confirmPassword" className="form-label">
                                                        Confirm Password
                                                    </label>
                                                    <div className="password-eyes quix-edit-password">
                                                        <input
                                                            type={!showConfirmPassword ? "password" : "text"}
                                                            className="form-control"
                                                            id="confirmPassword"
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    confirmPassword: e.target.value,
                                                                })
                                                            }
                                                            placeholder="Enter your confirm password"
                                                            defaultValue={formData?.confirmPassword}
                                                            maxLength={255}
                                                        />
                                                        <img
                                                            src={
                                                                !showConfirmPassword ? hidePassIcon : showPassIcon
                                                            }
                                                            height={"100%"}
                                                            width={"100%"}
                                                            onClick={() =>
                                                                setShowConfirmPassword(!showConfirmPassword)
                                                            }
                                                        />
                                                    </div>
                                                    {formErrors?.confirmPasswordError && (
                                                        <p className="text-danger account-dec m-0">
                                                            {formErrors?.confirmPasswordError}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="form-btn text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn custom-details-btn login-btn"
                                                        onClick={() => handleSubmit()}
                                                        disabled={loading}
                                                    >
                                                        Create New Password
                                                    </button>
                                                    {loading && (
                                                        <img className="loading-btn-img" src={loadingIcon} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* form outer */}
                            <div className="adsense-box">
                                <AdSenseAd
                                    client="ca-pub-4424794709901498"
                                    slot="3435754143"
                                    format="vertical"
                                    style={{ display: 'block', minWidth: "300px", maxWidth: "400px", width: "100%", height: "100%" }}
                                    responsive={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateNewPassword;
