import { checkNullValue, checkServerURL } from "../../../helper/helper";
import { API_URL } from "../../../utils/config";
import trashIcon from "../../assets/images/tash-user-icon.png";
import suspendUserIcon from "../../assets/images/block-icon.png";

export const handleMultiSelectItems = ({ event, selectedItemsIds, data }) => {
    let checked = event.target.checked;
    if (checked) {
        let newSelectedIDs = data.map((item) => item.id);
        selectedItemsIds((prevIDs) => [
            ...new Set([...prevIDs, ...newSelectedIDs]),
        ]);
    } else {
        selectedItemsIds([]);
    }
}

export const handleChangeSelectedItems = ({ event, selectedItemsIds, id }) => {
    const checked = event.target.checked;
    selectedItemsIds((prevSelectedUserIDs) => {
        if (checked) {
            if (!prevSelectedUserIDs.includes(id)) {
                return [...prevSelectedUserIDs, id];
            }
        } else {
            return prevSelectedUserIDs.filter((item) => item !== id);
        }
        return prevSelectedUserIDs;
    });
};

export const confirmationModalMessage = ({ action, type }) => {
    switch (action) {
        case "SUSPEND":
            return {
                title: "Are You Sure to Suspend the User?",
                message: <>Proceed with before taking any steps<br /> to suspend a user account.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "MULTI_SUSPEND":
            return {
                title: "Are You Sure to Suspend the Selected User?",
                message: <>Proceed with before taking any steps <br /> to suspend selected user accounts.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "ACTIVATE":
            return {
                title: "Reactivate User Account?",
                message: <>Are you sure you want to reactivate <br />the user account.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "MULTI_ACTIVATE":
            return {
                title: "Reactivate Selected User Account?",
                message: <>Are you sure you want to reactivate <br />selected user accounts.</>,
                type: action,
                icon: suspendUserIcon,
                tab: type
            };
        case "DELETE":
            return {
                title: "Are You Sure to Delete the User?",
                message: <>Proceed with before taking any steps <br /> to delete a user account.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_DELETE":
            return {
                title: "Are You Sure to Delete Selected Users?",
                message: <>Proceed with before taking any steps <br /> to delete selected user accounts.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "SCREENSHOT_DELETE":
            return {
                title: <>Would you like to permanently<br />delete this screenshot?</>,
                message: <>Once deleted, this screenshot will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_SCREENSHOT_DELETE":
            return {
                title: <>Would you like to permanently<br />delete selected screenshot?</>,
                message: <>Once deleted, these screenshot will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "VIDEO_DELETE":
            return {
                title: <>Would you like to permanently<br />delete this video?</>,
                message: <>Once deleted, this video will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_VIDEO_DELETE":
            return {
                title: <>Would you like to permanently<br />delete selected video?</>,
                message: <>Once deleted, these video will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        default:
            return null;
    }
}

export const userStatusHandler = (status) => {
    switch (Number(status)) {
        case 0:
            return {
                status: "Pending",
                class: "table-status-pending table-status-btn",
            };
        case 1:
            return {
                status: "Active",
                class: "table-status-active table-status-btn",
            };
        case 2:
            return {
                status: "Suspended",
                class: "table-status-suspend table-status-btn",
            };
        default:
            return {
                status: "Unregistered User",
                class: "table-status-default table-status-btn",
            };
    }
};

export const allUserCSV = (usersListData) => {
    const userDataCSVFormate = usersListData &&
        usersListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                Name: item?.name ? checkNullValue(item?.name) : "N/A",
                Email: item?.email ? checkNullValue(item?.email) : "N/A",
                Picture: item?.picture ? checkNullValue(checkServerURL(API_URL, item?.picture)) : "N/A",
                Role: item?.role ? checkNullValue(item?.role) : "N/A",
                Status: item?.status ? checkNullValue(item?.status) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let userDataCSV = { filename: "users-list", data: userDataCSVFormate };
    return userDataCSV;
}

export const suspendUserCSV = (suspendUsersListData) => {
    const suspendUserDataCSVFormate =
        suspendUsersListData &&
        suspendUsersListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                Name: item?.name ? checkNullValue(item?.name) : "N/A",
                Email: item?.email ? checkNullValue(item?.email) : "N/A",
                Picture: item?.picture ? checkNullValue(checkServerURL(API_URL, item?.picture)) : "N/A",
                Role: item?.role ? checkNullValue(item?.role) : "N/A",
                Status: item?.status ? checkNullValue(item?.status) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let suspendUserDataCSV = {
        filename: "suspend-users-list",
        data: suspendUserDataCSVFormate,
    };
    return suspendUserDataCSV;
}

export const csvUsersColumns = [
    { id: "ID", title: "ID" },
    { id: "Name", title: "Name" },
    { id: "Email", title: "Email" },
    { id: "Picture", title: "Picture" },
    { id: "Role", title: "Role" },
    { id: "Status", title: "Status" },
    { id: "Created_at", title: "Created At" },
    { id: "Updated_at", title: "Updated At" },
]

export const userVideoCSV = (userVideListData) => {
    const videoDataCSVFormate =
        userVideListData &&
        userVideListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                Name: item?.name ? checkNullValue(item?.name) : "N/A",
                Title: item?.title ? checkNullValue(item?.title) : "N/A",
                Summary: item?.summary ? checkNullValue(item?.summary) : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkNullValue(checkServerURL(API_URL, item?.thumbnail))
                    : "N/A",
                FileDuration: item?.file_duration ? checkNullValue(item?.file_duration) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let userVideoDataCSV = {
        filename: "user-video-list",
        data: videoDataCSVFormate,
    };
    return userVideoDataCSV;
}

export const csvVideoColumns = [
    { id: "ID", title: "ID" },
    { id: "Name", title: "Name" },
    { id: "Title", title: "Title" },
    { id: "Summary", title: "Summary" },
    { id: "Thumbnail", title: "Thumbnail" },
    { id: "FileDuration", title: "FileDuration" },
    { id: "Created_at", title: "Created At" },
    { id: "Updated_at", title: "Updated At" },
]

export const userScreenshotCSV = (userScreenshotListData) => {
    const screenshotDataCSVFormate =
        userScreenshotListData &&
        userScreenshotListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                Name: item?.name ? checkNullValue(item?.name) : "N/A",
                Title: item?.title ? checkNullValue(item?.title) : "N/A",
                Summary: item?.summary ? checkNullValue(item?.summary) : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkNullValue(checkServerURL(API_URL, item?.thumbnail))
                    : "N/A",
                FileSize: item?.file_size ? checkNullValue(Number(item?.file_size)) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let userScreenshotDataCSV = {
        filename: "user-screenshot-list",
        data: screenshotDataCSVFormate,
    };
    return userScreenshotDataCSV;
}

export const csvScreenshotColumns = [
    { id: "ID", title: "ID" },
    { id: "Name", title: "Name" },
    { id: "Title", title: "Title" },
    { id: "Summary", title: "Summary" },
    { id: "Thumbnail", title: "Thumbnail" },
    { id: "FileSize", title: "FileSize" },
    { id: "Created_at", title: "Created At" },
    { id: "Updated_at", title: "Updated At" },
]