import AdminLayout from "../../layout/adminLayout";
import React, { useState, useEffect } from "react";
import trashIcon from "../../assets/images/trash-icon.png";
import actionIcon from "../../assets/images/action-icon.png";
import profileRedirectIcon from "../../assets/images/user-profile-redirect.png";
import suspendIcon from "../../assets/images/slash-icon.png";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import trashUserIcon from "../../assets/images/tash-user-icon.png";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import { connect, useDispatch } from "react-redux";
import Table from "../../components/Table/Table";
import { checkServerURL } from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import { Link } from "react-router-dom";
import EditActionModal from "../../components/Modal/EditActionModal";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import Statistics from "../../components/Statistics/Statistics";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import {
  deleteUserByIdAdmin,
  getAllUserListAdmin,
  getPendingUserListAdmin,
  getSuspendUserListAdmin,
  SearchUserAdmin,
  updateUserByIdAdmin,
} from "../../../redux/actions/admin/users";
import { getStatisticsListAdmin } from "../../../redux/actions/admin/statistics";
import dateFormat from "dateformat";
import {
  allUserCSV,
  confirmationModalMessage,
  handleChangeSelectedItems,
  handleMultiSelectItems,
  suspendUserCSV,
  userStatusHandler,
  csvUsersColumns,
} from "./helper";

const UsersList = ({
  usersData,
  statistics,
  suspendUserData,
  pendingUserData,
  suspendUsersNotFound,
  pendingUsersNotFound,
  isLoadingSuspendUser,
  isLoadingPendingUser,
  isLoading,
  notFound,
}) => {
  const [itemId, setItemId] = useState(null);
  const adminID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const dispatch = useDispatch();
  const usersListData = usersData?.data ? usersData?.data : [];
  const suspendUsersListData = suspendUserData?.data
    ? suspendUserData?.data
    : [];
  const pendingUsersListData = pendingUserData?.data
    ? pendingUserData?.data
    : [];
  const statisticsData = statistics?.data ? statistics?.data : [];

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (adminID) {
      dispatch(getAllUserListAdmin(Number(adminID)));
      dispatch(getStatisticsListAdmin(Number(adminID)));
      dispatch(getSuspendUserListAdmin(Number(adminID)));
      dispatch(getPendingUserListAdmin(Number(adminID)));
    }
  }, [dispatch]);

  // handle search all users csv data
  let userDataCSV = allUserCSV(usersListData);
  // handle search suspend users csv data
  let suspendUserDataCSV = suspendUserCSV(suspendUsersListData);

  // handle search all users
  const handleSearchUsers = (data) => {
    if (data) {
      const payload = {
        text: data,
        query: "user",
        admin_id: Number(adminID),
      };
      dispatch(SearchUserAdmin(payload));
    }
  };

  // handle search suspend users
  const handleSearchSuspendUsers = (data) => {
    if (data) {
      const payload = {
        text: data,
        query: "suspendUser",
        admin_id: Number(adminID),
      };
      dispatch(SearchUserAdmin(payload));
    }
  };

  // handle search pending users
  const handleSearchPendingUsers = (data) => {
    if (data) {
      const payload = {
        text: data,
        query: "pendingUser",
        admin_id: Number(adminID),
      };
      dispatch(SearchUserAdmin(payload));
    }
  };

  // handle reset all users and suspend users
  const handleReset = () => {
    dispatch(getAllUserListAdmin(Number(adminID)));
    dispatch(getSuspendUserListAdmin(Number(adminID)));
    dispatch(getPendingUserListAdmin(Number(adminID)));
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    action: "",
    title: "",
    message: "",
    type: "",
    icon: null,
  });
  const modalAction = (action, type) => {
    const modalContent = confirmationModalMessage({
      action: action,
      type: type,
    });
    if (modalContent) {
      setShowConfirmModal(true);
      setModalContent(modalContent);
    }
  };

  // handle all user data
  const [showTableAction, setShowTableAction] = useState(false);
  const [showAllUserData, setShowAllUserData] = useState([]);
  const visibleAllUsers = showAllUserData && showAllUserData;
  const [isSlctAllUsers, setIsSlctAllUsers] = useState(false);
  const [slctUsersIds, setSlctUsersIds] = useState([]);

  useEffect(() => {
    setIsSlctAllUsers(
      visibleAllUsers.length > 0 &&
      visibleAllUsers.every((user) => slctUsersIds.includes(user.id))
    );
    if (slctUsersIds?.length > 0) {
      setShowTableAction(true);
    } else {
      setShowTableAction(false);
    }
  }, [slctUsersIds, visibleAllUsers]);

  const handleSuspendAction = (res) => {
    const payload = {
      admin_id: Number(adminID),
      id: res?.id,
      status: res?.status,
    };
    setLoading(true);
    setShowConfirmModal(false);
    setItemId(null);
    setSlctUsersIds([]);
    dispatch(
      updateUserByIdAdmin(payload, (type, res) => {
        if (type === "success") {
          if (res?.data?.status === "1") {
            successMessageModal(
              "User Account Reactivated.",
              "Successful user account reactivated."
            );
          } else {
            successMessageModal(
              "User suspend successful.",
              "Successful suspend a user account."
            );
          }
          handleReset();
          dispatch(getStatisticsListAdmin(Number(adminID)));
          setLoading(false);
        } else if (type === "fail") {
          setLoading(false);
          errorMessageModal("Suspend User", res?.message);
        }
      })
    );
  };

  // delete users
  const handleDeleteAction = (res) => {
    const payload = {
      admin_id: Number(adminID),
      id: res?.id,
    };
    setLoading(true);
    setShowConfirmModal(false);
    setItemId(null);
    setSlctUsersIds([]);
    dispatch(
      deleteUserByIdAdmin(payload, (type, res) => {
        if (type === "success") {
          successMessageModal(
            "User Account Deleted successful.",
            "Successful delete a user account."
          );
          handleReset();
          dispatch(getStatisticsListAdmin(Number(adminID)));
          setLoading(false);
        } else if (type === "fail") {
          setLoading(false);
          errorMessageModal("Delete User", res?.message);
        }
      })
    );
  };

  const allUsersColumns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(event) =>
            handleMultiSelectItems({
              event,
              selectedItemsIds: setSlctUsersIds,
              data: visibleAllUsers,
            })
          }
          checked={isSlctAllUsers ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              slctUsersIds?.includes(row?.row?.original?.id) ? true : false
            }
            onChange={(event) =>
              handleChangeSelectedItems({
                event,
                selectedItemsIds: setSlctUsersIds,
                id: row?.row?.original?.id,
              })
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      accessor: "name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.picture ||
              row?.row?.original?.picture === "null" ||
              row?.row?.original?.picture === null
              ? dummyUserIcon
              : checkServerURL(API_URL, `${row?.row?.original?.picture}`)
          }
          name={row?.row?.original?.name}
        />
      ),
      sortType: (rowA, rowB) => {
        return rowA.original.name.toLowerCase().localeCompare(rowB.original.name.toLowerCase());
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (
        <>
          <a href={`mailto:${row?.row?.original?.email}`}>
            {row?.row?.original?.email?.toLowerCase()}
          </a>
        </>
      ),
      sortType: (rowA, rowB) => {
        return rowA.original.email.toLowerCase().localeCompare(rowB.original.email.toLowerCase());
      },
    },
    {
      Header: "Date & time",
      Cell: (row) => (
        <>
          {row?.row?.original?.created_at
            ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
            : "N/A"}
        </>
      ),
    },
    {
      Header: "Status user",
      Cell: (row) => (
        <>
          <div
            className={`btn ${userStatusHandler(row?.row?.original?.status).class
              }`}
          >
            {userStatusHandler(row?.row?.original?.status).status}
          </div>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              <div className="column-action">
                <Link
                  className="justify-content-between"
                  to={`/admin/manage-user-profile/${row?.row?.original?.id}`}
                  state={{ data: row?.row?.original }}
                >
                  <span>User Profile</span>
                  <img src={profileRedirectIcon} />
                </Link>
              </div>
              {row?.row?.original.status === "0" || row?.row?.original.status === "2" ? (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("ACTIVATE", "allUser");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Activate User</span>
                </div>
              ) : (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("SUSPEND", "allUser");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Suspend User</span>
                </div>
              )}
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  modalAction("DELETE", "allUser");
                  setItemId(row?.row?.original?.id);
                }}
              >
                <img src={trashIcon} />
                <span>Delete User</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  // suspend user delete
  const [showSuspendTableAction, setShowSuspendTableAction] = useState(false);
  const [showSuspendUserData, setShowSuspendUserData] = useState([]);
  const visibleSuspendUsers = showSuspendUserData && showSuspendUserData;
  const [isSlctSuspendUsers, setIsSlctSuspendUsers] = useState(false);
  const [slctSuspendUsersIds, setSlctSuspendUsersIds] = useState([]);

  useEffect(() => {
    setIsSlctSuspendUsers(
      visibleSuspendUsers?.length > 0 &&
      visibleSuspendUsers.every((user) =>
        slctSuspendUsersIds.includes(user.id)
      )
    );
    if (slctSuspendUsersIds?.length > 0) {
      setShowSuspendTableAction(true);
    } else {
      setShowSuspendTableAction(false);
    }
  }, [slctSuspendUsersIds, visibleSuspendUsers]);

  const getTabsIds = (tab) => {
    if (tab === "allUser") {
      return slctUsersIds;
    } else if (tab === "suspendUser") {
      return slctSuspendUsersIds;
    } else if (tab === "pendingUser") {
      return slctPendingUsersIds
    }
  }

  const callBackAction = (res, type, tab) => {
    if (res) {
      switch (type) {
        case "SUSPEND":
          return handleSuspendAction({ id: [itemId], status: "2" });
        case "MULTI_SUSPEND":
          return handleSuspendAction({
            id: getTabsIds(tab),
            status: "2",
          });
        case "ACTIVATE":
          return handleSuspendAction({ id: [itemId], status: "1" });
        case "MULTI_ACTIVATE":
        return handleSuspendAction({
          id: getTabsIds(tab),
          status: "1",
        });
        case "DELETE":
          return handleDeleteAction({ id: [itemId] });
        case "MULTI_DELETE":
          return handleDeleteAction({
            id: getTabsIds(tab),
          });
        default:
          return null;
      }
    }
  };

  const suspendUsersColumns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(event) =>
            handleMultiSelectItems({
              event,
              selectedItemsIds: setSlctSuspendUsersIds,
              data: visibleSuspendUsers,
            })
          }
          checked={isSlctSuspendUsers ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              slctSuspendUsersIds?.includes(row?.row?.original?.id)
                ? true
                : false
            }
            onChange={(event) =>
              handleChangeSelectedItems({
                event,
                selectedItemsIds: setSlctSuspendUsersIds,
                id: row?.row?.original?.id,
              })
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.picture ||
              row?.row?.original?.picture === "null" ||
              row?.row?.original?.picture === null
              ? dummyUserIcon
              : checkServerURL(API_URL, `${row?.row?.original?.picture}`)
          }
          name={row?.row?.original?.name}
        />
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (
        <>
          <a href={`mailto:${row?.row?.original?.email}`}>
            {row?.row?.original?.email}
          </a>
        </>
      ),
    },
    {
      Header: "Date & time",
      Cell: (row) => (
        <>
          {row?.row?.original?.created_at
            ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
            : "N/A"}
        </>
      ),
    },
    {
      Header: "Status user",
      Cell: (row) => (
        <>
          <div
            className={`btn ${userStatusHandler(row?.row?.original?.status).class
              }`}
          >
            {userStatusHandler(row?.row?.original?.status).status}
          </div>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              <div className="column-action">
                <Link
                  className="justify-content-between"
                  to={`/admin/manage-user-profile/${row?.row?.original?.id}`}
                  state={{ data: row?.row?.original }}
                >
                  <span>User Profile</span>
                  <img src={profileRedirectIcon} />
                </Link>
              </div>
              {row?.row?.original.status === "1" ? (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("SUSPEND", "suspendUser");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Suspend User</span>
                </div>
              ) : (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("ACTIVATE", "suspendUser");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Activate User</span>
                </div>
              )}
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  modalAction("DELETE", "suspendUser");
                  setItemId(row?.row?.original?.id);
                }}
              >
                <img src={trashIcon} />
                <span>Delete User</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  // suspend user delete
  const [showPendingTableAction, setShowPendingTableAction] = useState(false);
  const [showPendingUserData, setShowPendingUserData] = useState([]);
  const visiblePendingUsers = showPendingUserData && showPendingUserData;
  const [isSlctPendingUsers, setIsSlctPendingUsers] = useState(false);
  const [slctPendingUsersIds, setSlctPendingUsersIds] = useState([]);

  useEffect(() => {
    setIsSlctPendingUsers(
      visiblePendingUsers?.length > 0 &&
      visiblePendingUsers.every((user) =>
        slctPendingUsersIds.includes(user.id)
      )
    );
    if (slctPendingUsersIds?.length > 0) {
      setShowPendingTableAction(true);
    } else {
      setShowPendingTableAction(false);
    }
  }, [slctPendingUsersIds, visiblePendingUsers]);

  const pendingUsersColumns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(event) =>
            handleMultiSelectItems({
              event,
              selectedItemsIds: setSlctPendingUsersIds,
              data: visiblePendingUsers,
            })
          }
          checked={isSlctPendingUsers ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              slctPendingUsersIds?.includes(row?.row?.original?.id)
                ? true
                : false
            }
            onChange={(event) =>
              handleChangeSelectedItems({
                event,
                selectedItemsIds: setSlctPendingUsersIds,
                id: row?.row?.original?.id,
              })
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.picture ||
              row?.row?.original?.picture === "null" ||
              row?.row?.original?.picture === null
              ? dummyUserIcon
              : checkServerURL(API_URL, `${row?.row?.original?.picture}`)
          }
          name={row?.row?.original?.name}
        />
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (
        <>
          <a href={`mailto:${row?.row?.original?.email}`}>
            {row?.row?.original?.email}
          </a>
        </>
      ),
    },
    {
      Header: "Date & time",
      Cell: (row) => (
        <>
          {row?.row?.original?.created_at
            ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
            : "N/A"}
        </>
      ),
    },
    {
      Header: "Status user",
      Cell: (row) => (
        <>
          <div
            className={`btn ${userStatusHandler(row?.row?.original?.status).class
              }`}
          >
            {userStatusHandler(row?.row?.original?.status).status}
          </div>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              <div className="column-action">
                <Link
                  className="justify-content-between"
                  to={`/admin/manage-user-profile/${row?.row?.original?.id}`}
                  state={{ data: row?.row?.original }}
                >
                  <span>User Profile</span>
                  <img src={profileRedirectIcon} />
                </Link>
              </div>
              {row?.row?.original.status === "1" ? (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("SUSPEND", "suspendUser");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Suspend User</span>
                </div>
              ) : (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    modalAction("ACTIVATE", "suspendUser");
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Activate User</span>
                </div>
              )}
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  modalAction("DELETE", "suspendUser");
                  setItemId(row?.row?.original?.id);
                }}
              >
                <img src={trashIcon} />
                <span>Delete User</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="quix-users-list">
      <AdminLayout pageTitle={"users"}>
        <div className="quix-users-content">
          <div className="table-management-section">
            <div className="management-header">
              <h4>User Management</h4>
              <p>You can activate, suspend, delete and update a user.</p>
            </div>
            <Filters
              filterList={[
                {
                  id: "all-user",
                  className: "all-user active",
                  title: "All User",
                },
                {
                  id: "suspend-users",
                  className: "suspend-users",
                  title: "Suspended Users",
                },
                {
                  id: "pending-users",
                  className: "pending-users",
                  title: "Pending Users",
                },
              ]}
            />
          </div>
          <Statistics
            viewTitle={""}
            viewURl={""}
            statistics={[
              { label: "Total Users", value: `${statisticsData?.total_user}` },
              {
                label: "Active pro plan",
                value: `${statisticsData?.pro_active_plans}`,
              },
              {
                label: "Suspended Users",
                value: `${statisticsData?.total_suspend_user}`,
              },
              {
                label: "Pending Users",
                value: `${statisticsData?.total_pending_user}`,
              },
            ]}
          />
          <FilterContent
            filterContent={[
              {
                id: "all-user",
                className: "show active all-user-tab",
                childrenData: (
                  <Table
                    csvColumns={csvUsersColumns}
                    tableData={usersListData}
                    tableColumns={allUsersColumns}
                    csvData={userDataCSV}
                    isLoading={isLoading}
                    notFound={notFound}
                    searchCallBack={(e) => handleSearchUsers(e)}
                    resetCallBack={() => handleReset()}
                    setShowItems={(e) => setShowAllUserData(e)}
                    searchBar={true}
                    tableType={"user"}
                    showAction={showTableAction}
                    actionBtn={
                      <>
                        <button
                          className="btn delete-btn"
                          onClick={() => modalAction("MULTI_DELETE", "allUser")}
                        >
                          <img src={trashUserIcon} />
                          Delete
                        </button>
                        <button
                          className="btn"
                          onClick={() =>
                            modalAction("MULTI_SUSPEND", "allUser")
                          }
                        >
                          <img src={suspendIcon} />
                          Suspend
                        </button>
                      </>
                    }
                  />
                ),
              },
              {
                id: "suspend-users",
                className: "suspend-users-tab",
                childrenData: (
                  <Table
                    csvColumns={csvUsersColumns}
                    tableData={suspendUsersListData}
                    tableColumns={suspendUsersColumns}
                    csvData={suspendUserDataCSV}
                    isLoading={isLoadingSuspendUser}
                    notFound={suspendUsersNotFound}
                    searchCallBack={(e) => handleSearchSuspendUsers(e)}
                    resetCallBack={() => handleReset()}
                    searchBar={true}
                    setShowItems={(e) => setShowSuspendUserData(e)}
                    showAction={showSuspendTableAction}
                    tableType={"suspend-user"}
                    actionBtn={
                      <>
                        <button
                          className="btn delete-btn"
                          onClick={() =>
                            modalAction("MULTI_DELETE", "suspendUser")
                          }
                        >
                          <img src={trashUserIcon} />
                          Delete
                        </button>
                        <button
                          className="btn"
                          onClick={() =>
                            modalAction("MULTI_ACTIVATE", "suspendUser")
                          }
                        >
                          <img src={suspendIcon} />
                          Activate User
                        </button>
                      </>
                    }
                  />
                ),
              },
              {
                id: "pending-users",
                className: "pending-users-tab",
                childrenData: (
                  <Table
                    csvColumns={csvUsersColumns}
                    tableData={pendingUsersListData}
                    tableColumns={pendingUsersColumns}
                    csvData={suspendUserDataCSV}
                    isLoading={isLoadingPendingUser}
                    notFound={pendingUsersNotFound}
                    searchCallBack={(e) => handleSearchPendingUsers(e)}
                    resetCallBack={() => handleReset()}
                    searchBar={true}
                    setShowItems={(e) => setShowPendingUserData(e)}
                    showAction={showPendingTableAction}
                    tableType={"pending-user"}
                    actionBtn={
                      <>
                        <button
                          className="btn delete-btn"
                          onClick={() =>
                            modalAction("MULTI_DELETE", "pendingUser")
                          }
                        >
                          <img src={trashUserIcon} />
                          Delete
                        </button>
                        <button
                          className="btn"
                          onClick={() =>
                            modalAction("MULTI_ACTIVATE", "pendingUser")
                          }
                        >
                          <img src={suspendIcon} />
                          Activate User
                        </button>
                      </>
                    }
                  />
                ),
              },
            ]}
          />
        </div>
        {showConfirmModal && (
          <ConfirmationModal
            isShowModal={showConfirmModal}
            closeModal={setShowConfirmModal}
            callBack={(res) =>
              callBackAction(res, modalContent?.type, modalContent?.tab)
            }
            isLoading={false}
            isDisabled={false}
            icon={modalContent?.icon}
            btnText={"Continue"}
            messageTitle={modalContent?.title}
            description={modalContent?.message}
          />
        )}
      </AdminLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersData: state?.users?.list,
  isLoading: state?.users?.is_loading,
  notFound: state?.users?.not_found,
  suspendUserData: state?.users?.suspend_users_list,
  isLoadingSuspendUser: state?.users?.is_loading_suspend_users,
  suspendUsersNotFound: state?.users?.not_found_suspend_users,
  pendingUserData: state?.users?.pending_users_list,
  isLoadingPendingUser: state?.users?.is_loading_pending_users,
  pendingUsersNotFound: state?.users?.not_found_pending_users,
  statistics: state?.admin_statistics?.list,
});

export default connect(mapStateToProps, {
  getAllUserListAdmin,
  getSuspendUserListAdmin,
})(UsersList);
